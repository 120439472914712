/*-------------------------
    Banner
-------------------------*/
.tokenomics {
    padding: 255px 0 0 0;
    position: relative;
    z-index: 2;
    min-height: 600px;
    @media #{$laptop-device} {
        padding: 220px 0 0;
    }
    @media #{$smlg-device} {
        padding: 200px 0 0;
        min-height: 100%;
    }
    
    @media #{$md-layout} {
        padding: 160px 0 0;
    } 
    @media #{$sm-layout} {
        padding: 150px 0 0;
    }

    .banner-content {
        @media #{$md-layout}{
            margin-bottom: 90px;
        }
        @media #{$sm-layout}{
            margin-bottom: 60px;
        }
        @media #{$large-mobile}{
            margin-bottom: 40px;
        }
        .title {
            font-size: 50px;
            text-align: center;
            font-weight: 700;
            color: var(--color-dark);
            @media #{$laptop-device} {
                font-size: 45px;
            }
            @media #{$smlg-device} {
                font-size: 40px;
            }
            @media #{$md-layout} {
                font-size: 32px;
            }
            @media #{$sm-layout} {
                font-size: 28px;
            }
        }
        .subtitle {
            font-size: var(--font-body-1);
            margin-bottom: 40px;
            width: 90%;
        }
        .axil-btn {
            display: block;
            width: max-content;
            margin: 0px auto 0px auto;
            padding: 28px 45px;
            box-shadow: 0 44px 84px -24px #5956e9;
            @media #{$md-layout} {
                padding: 20px 35px;
            }
        }
        .btn-group {
            display: flex;
            align-items: center;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
            }
            a {
                margin-right: 40px;
                @media #{$large-mobile} {
                    margin-right: 0;
                    margin-bottom: 30px;
                }
                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
        }
        .about-btn {
            font-weight: 500;
            color: var(--color-primary);
            position: relative;
            padding-left: 50px;
            &::before {
                content: "";
                height: 1px;
                width: 34px;
                background-color: var(--color-primary);
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                transition: var(--transition);
            }
            &:hover {
                &::before {
                    width: 100%;
                }
            }
        }
    }
    .banner-thumbnail {
        position: relative;
    }
    .banner-social {
        display: flex;
        align-items: center;
        .border-line {
            flex: 1;
            height: 1px;
            width: 100%;
            background-color: var(--color-body);
            margin-right: 30px;
            @media #{$large-mobile} {
                display: none;
            }
        }
        .social-icon {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li {
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    color: var(--color-body);
                    i, svg {
                        margin-right: 8px;
                        font-size: 20px;
                        color: var(--color-gray-4);
                        transition: var(--transition);
                    }
                    &:hover {
                        color: var(--color-primary);
                        i {
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
    &.tokenomics-one {
        margin: 100px 95px 0;
        padding: 100px 100px 100px 100px;
        background-color: var(--color-light);
        border-radius: 60px;
        overflow: hidden;
        @media #{$laptop-device} {
            padding: 90px 0 75px 0px;
            min-height: 600px;
            margin: 130px 30px 0;
        }
        @media #{$smlg-device} {
            margin: 130px 30px 0;
            padding: 60px 0px;
            min-height: 400px;
        }
        @media #{$md-layout} {
            min-height: 100%;
            margin: 120px 30px 0;
            padding: 80px 0px;
        }
        @media #{$sm-layout} {
            margin: 120px 30px 0;
            padding: 40px 0px;
        }
        @media #{$large-mobile} {
            margin: 120px 15px 0;
        }
        .banner-content {
            margin-bottom: 0;
            img{
                display: block;
                margin: 0px auto;
                width: 80%; 
                @media #{$smlg-device} {
                    width: 90%;
                } 
                @media #{$md-layout} {
                    width: 100%;
                }           
            }
            .title {
                color: var(--color-dark);
                margin-bottom: 10px;
            }
        }
    }
    &.tokenomics-two {
        margin: 100px 95px 0;
        padding: 100px 100px 100px 100px;
        background-color: rgb(227, 241, 229);
        border-radius: 60px;
        overflow: hidden;
        @media #{$laptop-device} {
            padding: 90px 0 75px 0px;
            min-height: 600px;
            margin: 130px 30px 0;
        }
        @media #{$smlg-device} {
            margin: 130px 30px 0;
            padding: 60px 0px;
            min-height: 400px;
        }
        @media #{$md-layout} {
            min-height: 100%;
            margin: 120px 30px 0;
            padding: 80px 0px;
        }
        @media #{$sm-layout} {
            margin: 120px 30px 0;
            padding: 40px 0px;
        }
        @media #{$large-mobile} {
            margin: 120px 15px 0;
        }
        .banner-content {
            margin-bottom: 0;
            img{
                display: block;
                margin: 0px auto;
                width: 80%;
                @media #{$smlg-device} {
                    width: 90%;
                } 
                @media #{$md-layout} {
                    width: 100%;
                }                 
            }
            .title {
                color: #408539;
                margin-bottom: 10px;
            }
        }
    }
    &.tokenomics-three {
        margin: 160px 95px 0;
        padding: 100px 100px 0px 100px;
        background-color: #f1f1f1;
        border-radius: 60px;
        overflow: hidden;
        @media #{$laptop-device} {
            padding: 90px 0 75px 0px;
            min-height: 600px;
            margin: 130px 30px 0;
        }
        @media #{$smlg-device} {
            margin: 130px 30px 0;
            padding: 60px 0px;
            min-height: 400px;
        }
        @media #{$md-layout} {
            min-height: 100%;
            margin: 120px 30px 0;
            padding: 80px 0px;
        }
        @media #{$sm-layout} {
            margin: 120px 30px 0;
            padding: 40px 0px;
        }
        @media #{$large-mobile} {
            margin: 120px 15px 0;
        }
        .banner-content {
            margin-bottom: 0;
            img{
                display: block;
                margin: 0px auto 50px auto;
                width: 60%; 
                @media #{$smlg-device} {
                    width: 80%;
                } 
                @media #{$md-layout} {
                    width: 90%;
                }
                @media #{$sm-layout} {
                    width: 100%;
                }                
            }
            .title {
                color: var(--color-link);
                margin-bottom: 10px;
            }
        }
    }
}