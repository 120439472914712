/*-------------------------
    Button
-------------------------*/
.axil-btn {
    &.cursor-pointer{
        cursor: pointer;
    }
    border-radius: 20px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.04em;
    padding: 18px 55px;
    height: auto;
    text-align: center;
    transition: var(--transition);
    position: relative;
    z-index: 1;
    &.btn-borderd {
        border: var(--border-gray);
        color: var(--color-dark);
        background-color: transparent;
        &:hover {
            background-color: var(--color-primary);
            color: var(--color-white);
            border-color: var(--color-primary);
        }
    }
    &.btn-fill-primary {
        background-color: var(--color-primary);
        color: var(--color-white);
        border: 1px solid var(--color-primary);
        overflow: hidden;
        &::after {
            content: "";
            height: 300px;
            width: 300px;
            background-color: var(--color-link);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%) scale(0);
            transition: .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            z-index: -1;
        }
        &:hover {
            background-color: var(--color-link);
            border-color: var(--color-link);
            &::after {
                transform: translateY(-50%) translateX(-50%) scale(1);
            }
        }
    }
    &.btn-fill-promo {
        background-color: #8c379b;
        color: var(--color-white);
        border: 1px solid #8c379b;  
        overflow: hidden;
        margin-left: 20px;
        margin-right: 20px;
        &::after {
            content: "";
            height: 300px;
            width: 300px;
            background-color: #b151c2;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%) scale(0);
            transition: .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            z-index: -1;
        }
        &:hover {
            background-color: #b151c2;
            border-color: #b151c2;
            &::after {
                transform: translateY(-50%) translateX(-50%) scale(1);
            }
        }
    }
    &.btn-fill-buy {
        background-color: #FFD80C;
        color: #000;
        border: 1px solid #FFD80C;
        overflow: hidden;
        &::after {
            content: "";
            height: 300px;
            width: 300px;
            background-color: rgb(255, 230, 104);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%) scale(0);
            transition: .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            z-index: -1;
        }
        &:hover {
            background-color: rgb(255, 230, 104);
            border-color: rgb(255, 230, 104);
            &::after {
                transform: translateY(-50%) translateX(-50%) scale(1);
            }
        }
    }
    &.btn-fill-white {
        background-color: var(--color-white);
        box-shadow: 0px 48px 48px 0px rgba(#000000, 0.12);
        &:hover {
            background-color: var(--color-accent1);
            color: var(--color-white);
        }
    }
    &.btn-large {
        padding: 23px 55px;
        @media #{$md-layout} {
            padding: 18px 35px;
        }
    }
    &.btn-large-fluid {
        padding: 23px;
        width: 100%;
    }
    &.btn-fluid {
        width: 100%;
    }
}
