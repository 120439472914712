/*-------------------------
   NFT Eras
-------------------------*/

.nfts-eras{    
    display: block;
    width: 100%;
    height: auto;
    ul {
        
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        // flex-wrap: wrap;
        li {
            list-style: none;
            width: 30%;
            border-radius: 5px;
            padding: 0px;
            margin-right: 3%;
            margin-bottom: 3%;
            overflow: hidden;
            border-radius: 10px;
            img{
                width: 100%;
                cursor: pointer;
            }
        }
    }
}
.nft-full {
    width: 100%;
    max-width: 600px;
    margin: 0px;
    padding: 0px;
    height: auto;
    border-radius: 25px;
    overflow: hidden;
}
.nft-full-center {
    width: 100%;
    margin: 0px;
    padding: 0px;
    height: auto;
    border-radius: 25px;
}
